






















































































































@import 'node_modules/vue-select/src/scss/vue-select.scss';
@import './src/utilities/config';

.v-select {
  .vs__open-indicator {
    width: 15px;
    height: auto;
  }

  // Unset borders, margin and padding to use those provided by bootstrap
  // Otherwise, need to redefine all vue-select styles here
  .vs__dropdown-toggle {
    border: 0;
    display: -webkit-box;
  }

  .vs__search {
    margin: 0;
    padding: 0;
  }

  .vs__actions {
    margin: 0;
    padding: 0;
  }

  .vs__selected {
    margin: 0;
    padding: 0;
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vs__selected-options {
    height: 20px;
  }

  // Replicates bootstrap-style focus
  &:focus-within {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}

// Replicates bootstrap-style invalid/valid
.was-validated .invalid {
  border-color: $form-feedback-invalid-color;
  padding-right: calc(1.5em + 0.75em);
  background-image: escape-svg($form-feedback-icon-invalid);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .valid {
  border-color: $form-feedback-valid-color;
}
